import { resolveRoute } from "vike/routing";

// Route: /clubs/@uuid/@view? (default = about-us)
// -- Note that this currently does not validate that the @view param is valid
export const route = (pageContext) => {
  const defaultView = resolveRoute("/clubs/@uuid", pageContext.urlPathname);
  if (defaultView.match) {
    defaultView.routeParams.view = "about-us";
    return defaultView;
  }
 
  const clubView = resolveRoute('/clubs/@uuid/@view', pageContext.urlPathname);
  return clubView.match ? clubView : false;
};
