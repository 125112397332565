import { resolveRoute } from "vike/routing";

export const route = (pageContext) => {
  const tagView = resolveRoute("/library/tags/@uuid", pageContext.urlPathname);
  if (tagView.match) return tagView;

  const statusView = resolveRoute("/library*", pageContext.urlPathname);
  if (!statusView.match) return false;

  statusView.routeParams.status = statusView.routeParams["*"].slice(1) || "current";
  return statusView;
};
