import { resolveRoute } from "vike/routing";

export const route = (pageContext) => {
  const currentUserRoute = resolveRoute("/reading-challenge", pageContext.urlPathname);
  if (currentUserRoute.match) return currentUserRoute;

  const otherUserRoute = resolveRoute("/users/@username/reading-challenge", pageContext.urlPathname);
  if (otherUserRoute.match) return { ...otherUserRoute, precedence: 10 };

  return false;
};
