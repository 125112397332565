import { resolveRoute } from "vike/routing";

// Route: /discover/@view? (default = books)
// -- Note that this currently does not validate that the @view param is valid,
// -- so all paths beginning with /discover will be caught and render ./+Page.jsx
export const route = (pageContext) => {
  const result = resolveRoute("/discover*", pageContext.urlPathname);
  if (!result.match) return false;

  result.routeParams.view = result.routeParams["*"].slice(1) || "books";
  return result;
};
